import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import * as styles from "../styles/article.module.sass"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <article className={ styles.article }>
      <header className="content-header">
        <h1>404: Not Found</h1>
        <p>The page you're looking for does not exist.</p>
      </header>
    </article>
  </Layout>
)

export default NotFoundPage
